/** @jsx jsx */
import { useEffect  } from 'react';
import { jsx } from '@emotion/core';
import { withTheme } from 'emotion-theming';
import { VEHICLE_LISTING_TYPE } from '@packages/fdpq-core/constants/app';
import { connect } from 'react-redux';

import {
  Button,
  Column,
  Description,
  FlexCell,
  FlexContainer,
  FlexRow,
  Footer,
} from '@packages/fdpq-common';
import { widgetSize } from '@packages/fdpq-common/constants/ui';
import DealerList from '../../fdpq-dealer-selector/components/DealerList';
import KeylineContainer from '../../fdpq-common/components/KeylineContainer';
import { text } from '../../fdpq-core/config/vars';
import { mapStateToProps, mapDispatchToProps } from '../containers/ThankYouPage';
import { getPixallEventData } from '@packages/fdpq-core/constants/app';
import { sendEventPixall, createCoxAutoCorrelationId } from '@packages/fdpq-common/analytics/pixall';

const ThankYouPage = ({
  onNextPageSelected,
  onButtonPress,
  theme,
  selectedDealers,
  listingTypeConfig,
  leadpartnerfranchiseid,
  leadFranchiseId,
  zipCode,
}) => {
  
  const coxAutoCorrelationId = createCoxAutoCorrelationId();
  const pixallData = getPixallEventData(listingTypeConfig.vehicleListingType);
  const eventData = {
    eventType: pixallData.EVENT_TYPE_IMPRESSION,
    eventSource: pixallData.EVENT_SOURCE,
    label: pixallData.LABEL_STEP_IMPRESSION,
    assistChannel: pixallData.ASSIST_CHANNEL,
    assistProduct: pixallData.ASSIST_PRODUCT,
    assistSubchannel: pixallData.ASSIST_SUBCHANNEL,
    pageType: pixallData.PAGE_TYPE,
    pageId: pixallData.PAGE_ID,
  }

  const vehicleData = { 
    zipCode,
  };

  const getAnotherQuoteButton = (
    <Button
      type="submit"
      onClick={(e) => {
        onButtonPress();
        onNextPageSelected(e);
      }}
    >
      Get Another Quote
    </Button>
  )

  useEffect(() => {
    sendEventPixall(coxAutoCorrelationId, eventData, vehicleData);
  }, []);

  return (
    <Column id="test-thank-you-page">
      <FlexContainer>
        <KeylineContainer style={{ paddingBottom: listingTypeConfig.vehicleListingType === VEHICLE_LISTING_TYPE.TRADEIN ? '0px' : '16px' }}>
          <FlexRow>
            <FlexCell fullWidth={true}>
              <Description
                title={text.global.thankYouView.titleText}
                subtitle={text.global.thankYouView.subtitleText}
              />
            </FlexCell>
          </FlexRow>
        </KeylineContainer>
        <FlexRow style={{ marginTop: theme.widgetSize === widgetSize.SMALL ? '10px' : '0' }}>
          <DealerList displayDealerList={selectedDealers} vehicleListingTypeConfig={listingTypeConfig} />
        </FlexRow>
      </FlexContainer>
      <Footer>
        <FlexContainer>
          <FlexRow>
            <FlexCell
              columnSpan={{ lg: 3, md: 3, sm: 12 }}
              style={{ gridColumn: theme.widgetSize !== widgetSize.SMALL ? theme.button.desktopGridColumn : '' }}
            >
             {(leadFranchiseId.length === 0 && leadpartnerfranchiseid.length === 0) ? getAnotherQuoteButton : ''}
            </FlexCell>
          </FlexRow>
        </FlexContainer>
      </Footer>
    </Column>
  )

};

// export default withTheme(ThankYouPage);
const ThankYou = withTheme(ThankYouPage);
export default connect(mapStateToProps, mapDispatchToProps)(ThankYou);
